.btn-booking {
  position: fixed;
  right: 40px;
  bottom: 350px;
  z-index: 8;
  border-radius: 50px;
  background: #fff;
  width: 60px;  /* Kích thước mặc định cho desktop */
  height: 60px;
}

.kenit-alo-circle {
  position: absolute;
  width: 70px;  /* Kích thước mặc định cho desktop */
  height: 70px;
  border: 2px solid red;
  border-radius: 50%;
  top: -5px;
  left: -5px;
  z-index: 1;
}

.kenit-alo-circle-fill {
  position: absolute;
  width: 80px;  /* Kích thước mặc định cho desktop */
  height: 80px;
  background-color: rgba(111, 136, 133, 0.2);
  border-radius: 50%;
  top: -10px;
  left: 30px;
  z-index: 0;
}

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.infinite {
  animation-iteration-count: infinite;
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomIn {
  from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
      opacity: 1;
  }
}

.pulse {
  animation-name: pulse;
}

/* Kích thước cho màn hình tablet */
@media (max-width: 1024px) {
  .btn-booking {
      width: 50px; /* Kích thước thu nhỏ cho tablet */
      height: 50px;
      bottom: 300px; /* Điều chỉnh vị trí nếu cần */
  }



  .kenit-alo-circle {
      width: 60px; /* Kích thước thu nhỏ cho tablet */
      height: 60px;
      
  }

  .kenit-alo-circle-fill {
      width: 70px; /* Kích thước thu nhỏ cho tablet */
      height: 70px;
      top: -10px;
      left: 25px;
  }
}

/* Kích thước cho màn hình di động */
@media (max-width: 768px) {
  .btn-booking {
      width: 40px; /* Kích thước thu nhỏ cho di động */
      height: 40px;
      bottom: 250px; /* Điều chỉnh vị trí nếu cần */
  }

  .kenit-alo-circle {
      width: 50px; /* Kích thước thu nhỏ cho di động */
      height: 50px;
  }

  .kenit-alo-circle-fill {
      width: 60px; /* Kích thước thu nhỏ cho di động */
      height: 60px;
  }
}
