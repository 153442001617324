/* Add margin around each category card */
.category-card {
  border-radius: 15px; /* Rounded corners for the card */
  overflow: hidden; /* Ensures content doesn't overflow the rounded corners */
  transition: transform 0.3s ease; /* Smooth scale transition on hover */
  border: 1px;
  margin: 10px; /* Add margin around each card */
}

/* Scale up the card on hover */
.category-card:hover {
  transform: scale(1.05);
}

/* Center the title-main container */
.title {
  text-align: center; /* Horizontally center the text */
  margin-bottom: 20px; /* Optional: Add spacing below the title */
  padding-top: 30px;
  font-size: 40px ; /* Default font size */
  color: #F59F05 !important;
  font-weight: bold;
}



/* Adjust category card title */
.category-card .card-title {
  font-size: 1.2rem; /* Default font size for card titles */
  color: #ffffff;
}

/* Media Query for small screens (phones) */
@media only screen and (max-width: 576px) {
  .title  {
    font-size: 1.5rem; /* Smaller font size for small screens */
  }
  
  .category-card .card-title {
    font-size: 1rem; /* Smaller card title for small screens */
  }

  .category-card {
    margin: 5px; /* Reduce margin for small screens */
  }
}

/* Media Query for medium screens (tablets) */
@media only screen and (min-width: 577px) and (max-width: 992px) {
  .title  {
    font-size: 2.5rem; /* Medium font size for tablet screens */
  }

  .category-card .card-title {
    font-size: 1.1rem; /* Medium card title size for tablets */
  }

  .category-card {
    margin: 8px; /* Adjust margin for medium screens */
  }
}

/* Media Query for large screens (desktops) */
@media only screen and (min-width: 993px) {
  .title  {
    font-size: 40px; /* Larger font size for large screens */
  }

  .category-card .card-title {
    font-size: 1.2rem; /* Default card title size for large screens */
  }

  .category-card {
    margin: 10px; /* Default margin for large screens */
  }
}
