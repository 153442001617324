.btn-mail {
  position: fixed;
  right: 40px;
  bottom: 150px;
  z-index: 8;
  border-radius: 50px;
  background-color: #65b741;
  width: 60px; /* Kích thước mặc định cho desktop */
  height: 60px;
}

.kenit-alo-circle {
  position: absolute;
  width: 80px; /* Kích thước mặc định cho desktop */
  height: 80px;
  border-radius: 50%;
  top: -10px;
  left: -10px;
  z-index: 1;
}

.kenit-alo-circle-fill {
  position: absolute;
  width: 80px; /* Kích thước mặc định cho desktop */
  height: 80px;
  background-color: rgba(111, 136, 133, 0.2);
  border-radius: 50%;
  top: -10px;
  left: 30px;
  z-index: 0;
}

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.infinite {
  animation-iteration-count: infinite;
}

.zoomIn {
  animation-name: zoomIn;
}

.mail-icon-container{
  width: 50px;
  padding:5px;
  height: 50px;  
}

.mail-icon{
  width:35px;
   height:35px;
   margin:7px;
   color: white;
}

@keyframes zoomIn {
  from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
      opacity: 1;
  }
}

.pulse {
  animation-name: pulse;
}

/* Kích thước cho màn hình tablet */
@media (max-width: 1024px) {
  .btn-mail {
      width: 50px; /* Kích thước thu nhỏ cho tablet */
      height: 50px;
      bottom: 120px; /* Điều chỉnh vị trí nếu cần */
  }

  .kenit-alo-circle {
      width: 70px; /* Kích thước thu nhỏ cho tablet */
      height: 70px;
  }

  .kenit-alo-circle-fill {
      width: 70px; /* Kích thước thu nhỏ cho tablet */
      height: 70px;
      top: -10px;
      left: 25px;
  }

  .mail-icon-container {
      width: 45px; /* Kích thước thu nhỏ cho tablet */
      height: 45px;
  }

  .mail-icon {
      width: 25px; /* Kích thước thu nhỏ cho tablet */
      height: 25px;
  }
}

/* Kích thước cho màn hình di động */
@media (max-width: 768px) {
  .btn-mail {
      width: 40px; /* Kích thước thu nhỏ cho di động */
      height: 40px;
      bottom: 90px; /* Điều chỉnh vị trí nếu cần */
  }

  .kenit-alo-circle {
      width: 60px; /* Kích thước thu nhỏ cho di động */
      height: 60px;
  }

  .kenit-alo-circle-fill {
      width: 60px; /* Kích thước thu nhỏ cho di động */
      height: 60px;
      top: -10px;
      left: 20px;
  }

  .mail-icon-container {
      width: 40px; /* Kích thước thu nhỏ cho di động */
      height: 40px;
      padding: 0;
      
  }

  .mail-icon {
      width: 20px; /* Kích thước thu nhỏ cho di động */
      height: 20px;
      margin: 10px;
      
  }
}
