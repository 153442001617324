.main-product{
    padding-top: 100px;
    padding-bottom: 3rem;
    padding-right: 3rem;
    max-width: 100%;
    background-color: white;
}
.banner{
    width: 100%;
    margin-bottom: 20px;
}
.menu-product{
    width: 300px;
    margin-left: 5rem;
}

.title-menu{
    color: white !important;
    padding-top: 5px;
    text-align: center;
    background-color: #F59F05 !important;
    font-size: 20px;
}

.breadcrumb-main{
    padding-left: 5rem;
}

.d-md-none{
    margin-left: 20px;
}