/* .partners-carousel {
    padding: 2rem;
    background-color: #f8f8f8;
    text-align: center;
  } */
  

  
  .partner-slide {
    padding: 0.25rem;
   
  }
  
  .partner-slide img {
    width: 250px;
    height: auto;
    /* max-width: 100%; */
    border-radius: 10px;
    
  }
  
  .slick-arrow {
    background-color: #3c9c3d; /* Matching green color */
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .slick-prev {
    left: 50px;
  }
  
  .slick-next {
    right: 50px;
  }
  