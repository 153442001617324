/* Các thuộc tính chung của nút gọi */
.call-now-button {
    position: fixed;
    bottom: 50px;
    left: 20px;
    z-index: 1000;
    color: #fff;
    border-radius: 50px;
    margin: 0 auto;
    background-color: red;
}

/* Điều chỉnh văn bản gọi */
.call-text {
    color: #fff;
    display: table;
    padding: 10px;
    border-radius: 21px;
    height: 34px;
    line-height: 14px;
    font-size: 14px;
    margin: 10px 5px 10px 50px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

/* Điều chỉnh hình tròn xung quanh */
.quick-alo-ph-circle-call {
    position: absolute;
  width: 70px;  /* Kích thước mặc định cho desktop */
  height: 70px;
  border: 2px solid white;
  border-radius: 50%;
  top: -5px;
  left: -5px;
  z-index: 1;
}

/* Điều chỉnh hình tròn trong */
.quick-alo-ph-circle-fill-call {
    position: absolute;
  width: 80px; /* Kích thước mặc định cho desktop */
  height: 80px;
  background-color: rgba(111, 136, 133, 0.2);
  border-radius: 50%;
  top: -10px;
  left: 30px;
  z-index: 0;
}

.icon {
    display: flex;
}

.quick-alo-ph-btn-icon {
    color: white;
    font-size: 30px;
    line-height: 40px;
    position: absolute;
    bottom: 10px;
    left: 30px;
    transform: translateX(-50%);
    animation: vibrate 1.2s infinite ease-in-out;
    z-index: 3;
}

/* Hiệu ứng cho icon */
@keyframes vibrate {
    0% {
        transform: translateX(-50%) translateY(0);
    }
    8.3% {
        transform: translateX(-50%) translateY(-2px);
    }
    16.7% {
        transform: translateX(-50%) translateY(2px);
    }
    25% {
        transform: translateX(-50%) translateY(-2px);
    }
    33.3% {
        transform: translateX(-50%) translateY(0);
    }
    100% {
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: translateX(-50%) scale(0.9);
    }
    50% {
        transform: translateX(-50%) scale(1.1);
    }
    100% {
        transform: translateX(-50%) scale(0.9);
    }
}

/* Media Query cho màn hình nhỏ (điện thoại) */
@media only screen and (max-width: 600px) {
    .call-now-button {
        bottom: 30px;
        left: 20px;
    }

    .quick-alo-ph-circle {
        width: 50px;
        height: 50px;
        border: 2px solid rgb(232, 210, 210);
    }

    .quick-alo-ph-circle-fill {
        width: 40px;
        height: 40px;

    }

    .quick-alo-ph-btn-icon {
        font-size: 20px;
        line-height: 30px;
    }

    .call-text {
        font-size: 12px;
        height: 28px;
        line-height: 14px;
    }
}

/* Media Query cho màn hình trung bình (tablet) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .call-now-button {
        bottom: 40px;
        left: 15px;
    }

    .quick-alo-ph-circle {
        width: 60px;
        height: 60px;
        border: 2.5px solid rgb(232, 210, 210);
    }

    .quick-alo-ph-circle-fill {
        width: 50px;
        height: 50px;
    }

    .quick-alo-ph-btn-icon {
        font-size: 25px;
        line-height: 35px;
    }

    .call-text {
        font-size: 13px;
        height: 30px;
        line-height: 16px;
    }
}

/* Media Query cho màn hình lớn (desktop) */
@media only screen and (min-width: 1025px) {
    .call-now-button {
        bottom: 50px;
        left: 20px;
    }

    .quick-alo-ph-circle {
        width: 70px;
        height: 70px;
        border: 3px solid rgb(232, 210, 210);
    }

    .quick-alo-ph-circle-fill {
        width: 60px;
        height: 60px;
    }

    .quick-alo-ph-btn-icon {
        font-size: 30px;
        line-height: 40px;
    }

    .call-text {
        font-size: 14px;
        height: 34px;
        line-height: 18px;
    }
}
