.wrap-main {
  padding-top: 100px;
}
  
  .maxwidth {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .content-main {
    padding: 20px;
  }
  
  .w-clear::after {
    content: "";
    display: table;
    clear: both;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .title-main h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .time-main {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .content-detail {
    margin-bottom: 40px;
  }
  
  .text-justify {
    text-align: justify;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color: #666;
    background: #fff;
    letter-spacing: 0;
  }
  
  .text-center {
    text-align: center;
  }
  
  .share-line {
    margin: 20px 0;
  }
  
  .share {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .share_title {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .social-plugin .jssocials-shares {
    display: flex;
    gap: 10px;
  }
  
  .othernews {
    margin-top: 20px;
  }
  
  .list-news-other {
    list-style: none;
    padding-left: 0;
  }
  
  .list-news-other li {
    margin-bottom: 10px;
  }
  
  .pagination-home {
    margin-top: 20px;
  }
  
  /* Add any other custom styles here */
  /* Điều chỉnh cho màn hình máy tính bảng và điện thoại (<= 768px) */
@media screen and (max-width: 768px) {
  .wrap-main {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }

  .maxwidth {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .content-main {
    padding: 15px;
  }

  .title-main h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .time-main {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .text-justify {
    font-size: 14px;
  }

  .share {
    flex-direction: column;
    align-items: flex-start;
  }

  .share_title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .social-plugin .jssocials-shares {
    flex-direction: column;
    gap: 5px;
  }

  .othernews {
    margin-top: 15px;
  }

  .list-news-other li {
    margin-bottom: 8px;
  }

  .pagination-home {
    margin-top: 15px;
  }
}

/* Điều chỉnh cho màn hình rất nhỏ (<= 480px) */
@media screen and (max-width: 480px) {
  .wrap-main {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }

  .maxwidth {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .content-main {
    padding: 10px;
  }

  .title-main h1 {
    font-size: 18px;
  }

  .time-main {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .text-justify {
    font-size: 12px;
  }

  .share {
    flex-direction: column;
    align-items: flex-start;
  }

  .share_title {
    font-size: 12px;
  }

  .social-plugin .jssocials-shares {
    flex-direction: column;
    gap: 3px;
  }

  .othernews {
    margin-top: 10px;
  }
}