.wrap-main {
    padding-top: 100px;
}

.maxwidth {
    max-width: 1200px;
    margin: 0 auto;
}

.content-main {
    margin-top: 20px;
}

.w-clear::after {
    content: "";
    display: table;
    clear: both;
}

.title-main h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.time-main {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.content-detail p {
    margin-bottom: 20px;
    line-height: 1.6;
}

.content-detail img {
    max-width: 100%;
    height: auto;
}

.share-line {
    margin: 20px 0;
    border-top: 1px solid #ddd;
}

.share {
    display: flex;
    justify-content: space-between;
}

.share_title {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
}

.social-plugin .share-zalo,
.social-plugin .jssocials {
    margin-right: 10px;
}

.othernews {
    margin-top: 20px;
}

.list-news-other {
    list-style-type: none;
    padding: 0;
}

.list-news-other li {
    margin-bottom: 10px;
}

.list-news-other a {
    color: #007bff;
    text-decoration: none;
}

.list-news-other a:hover {
    text-decoration: underline;
}

.pagination-home {
    margin-top: 20px;
}
/* Điều chỉnh cho màn hình điện thoại (<= 768px) */
@media screen and (max-width: 768px) {
    .wrap-main {
        padding-top: 50px;
    }

    .maxwidth {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .title-main h2 {
        font-size: 20px;
    }

    .time-main {
        font-size: 12px;
        margin-bottom: 15px;
    }

    .content-main {
        margin-top: 15px;
    }

    .share {
        flex-direction: column;
        align-items: flex-start;
    }

    .share_title {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .social-plugin .share-zalo,
    .social-plugin .jssocials {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .othernews {
        margin-top: 15px;
    }

    .list-news-other li {
        margin-bottom: 8px;
    }

    .pagination-home {
        margin-top: 15px;
    }
}

/* Điều chỉnh cho màn hình rất nhỏ (<= 480px) */
@media screen and (max-width: 480px) {
    .wrap-main {
        padding-top: 30px;
    }

    .title-main h2 {
        font-size: 18px;
    }

    .time-main {
        font-size: 11px;
        margin-bottom: 10px;
    }

    .share_title {
        font-size: 12px;
    }

    .othernews {
        margin-top: 10px;
    }
}