#news {
    margin: 10px;
    background-color: #f9f9f9;
  }
  
  .maxwidth {
    max-width: 1200px;
    margin: 0 auto;
  }
  

  .relative {
    position: relative;
  }
  
  .hover-arrow {
    position: relative;
  }
  
  .owl-carousel {
    position: relative;
  }
  
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .owl-prev, .owl-next {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 10px;
  }
  
  .owl-prev {
    left: 0;
  }
  
  .owl-next {
    right: 0;
  }
  
  .owl-item {
    margin: 0 10px;
  }
  
  .image-container {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    padding: 4%;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0; /* Hide initially */
    transition: opacity 0.3s ease;
  }
  
  .image-container:hover .image-overlay {
    opacity: 1; /* Show on hover */
  }
  
  .overlay-title {
    font-size: 16px;
    font-weight: bold;
    margin: 2%;
  }
  
  .overlay-link {
    font-size: 16px;
    color: #e91e63;
    text-decoration: none;
    font-weight: bold;
  }
  
  .info-hinhthuc {
    padding: 15px;
  }
  
  .desc-hinhthuc {
    margin: 10px 0;
  }
  
  .link-more {
    font-size: 16px;
    color: #e91e63;
    text-decoration: none;

  }
  
  .text-center {
    text-align: center;
    padding: 20px;
  }
  
  @media (max-width: 1024px) {
    .owl-item {
      width: 100%;
    }
  }
  