#main {
    background-size: cover;
    margin: 0;
  }
  .validation-contact{
    padding: 10px;
  }

  .maxwidth {
    max-width: 100%;
    margin: 0 auto;
  }
  
  .content-main {
    padding: 20px;
  }
  
  .w-clear::after {
    content: "";
    display: table;
    clear: both;
  }
  
  .title-main h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #F59F05 !important;
  }
  
 .top-contact .row,
  .bottom-contact {
    margin-bottom: 0;
  }
  
 .article-contact {
    margin-bottom: 20px;
    background-color: rgb(217, 225, 232);
  }
  .article-contact-in{
    padding: 20px;
  }

.form-contact .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

  }

 .input-contact {
    margin-bottom: 10px;
  }
  
 .form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
  } 
  
.invalid-feedback {
    color: red;
    font-size: 14px;
  }
  
  /* .btn {
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
  } */
  
  .bottom-contact iframe {
    width: 100%;
    height: 450px;
    border: 0;
  }
  .img-contact{
    width: 100%;
    height: auto;
  }
 