#footer {
  margin-top: 3%;
  padding-top: 2%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  background-color: aliceblue;
}

.follow-us {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: black;
}

.footer-ul li {
  margin-bottom: 10px;
  font-size: 16px;
}

.qrcode {
  width: 100px !important;
  height: auto;
}

#footer-map {
  max-width: 100%;
  margin: 0 auto;
}

#footer-map iframe {
  width: 350px;
  height: 200px;
  border: 0;
}

.footer-news {
  margin-bottom: 10%;
}

.title-footer {
  color: #F59F05;
  margin-bottom: 5%;
}

.footer-powered {
  background-color: #F59F05;
  padding: 10px;
  text-align: center;
  color: white;
}

.follow-us img {
  width: 35px;
  height: auto;
  transition: transform 0.3s;
}

.follow-us img:hover {
  transform: scale(1.1);
}

.text-decoration-none {
  color: black;
  font-size: 120%;
}
/* Đối với màn hình nhỏ hơn 768px */
@media screen and (max-width: 768px) {
  #footer {
    padding-top: 10px;
    padding-left: 1.5%;
    padding-right: 1.5%;
  }

  .qrcode {
    width: 80px;
  }

  #footer-map iframe {
    width: 100%;
    height: 250px;
  }

  .footer-ul li {
    font-size: 14px;
  }
}

/* Đối với màn hình rất nhỏ hơn 480px */
@media screen and (max-width: 480px) {
  .qrcode {
    width: 70px;
  }

  .follow-us img {
    width: 30px;
  }

  .footer-ul li {
    font-size: 12px;
  }
}
