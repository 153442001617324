/* navbar.css */

/* Tinh chỉnh thuộc tính của Navbar để chứa các phần tử đúng cách */
.navbar-container {
  display: flex;
  flex-direction: column;
}

/* Sửa đổi nền và padding cho Navbar */
.navbar-expand-lg {
  background-color: wheat;
}

/* Điều chỉnh khoảng cách của Navbar.Brand */
/* .navbar-brand {
  margin-left: 8%;
  margin-right: 2%;
}
.align-left {
  display: flex;
  justify-content: flex-start;
} */
/* Định dạng ô tìm kiếm để phù hợp với các phần tử khác */
.search-box {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 50px;
  margin-left: 20px; /* Để căn chỉnh với các phần tử khác */
}

/* Định dạng input trong ô tìm kiếm */
.search-box input {
  padding: 6px;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  max-width: 180px;
  height: 25px;
}

/* Thay đổi padding cho liên kết điều hướng */
.nav-link {
  padding: 20px;
  color: black !important;
  font-weight: bold !important;
}

/* Đưa dropdown ngôn ngữ vào đúng vị trí */
.language-dropdown {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* Hiển thị menu con khi hover vào dropdown */
.nav-dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}


/* Đảm bảo menu con có màu nền và bo tròn */
.nav-dropdown .dropdown-menu {
  background-color: white;
  border-radius: 0;
  border: none;
}

/* Điều chỉnh các mục trong menu con */
.nav-dropdown .dropdown-item {
  color: black;
}

/* Điều chỉnh căn giữa văn bản trong Dropdown sau khi xóa caret */
.nav-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}



.search-box input {
  width: 100%; /* Ensure input field takes full width */
}

.align-left {
  display: flex;
  justify-content: flex-start;
  /* Optional: Để căn chỉnh nút với khoảng cách từ lề trái */
  padding-left: 0;
}
.button-introduce{
  background-color: wheat !important;
  padding: 20px;
  color: black !important;
  font-weight: bold !important;
  text-align: left;
}
.btn-primary{
  --bs-btn-border-color: wheat !important;
}

.navbar {
  --bs-navbar-padding-y: 0 o !important;
}

.free-shipping {
  color: green; /* Màu sắc cho văn bản */
  font-weight: bold;
  display: flex;
  align-items: center;
  padding:  0;
}


/* Ẩn phần "Giao hàng miễn phí" trên màn hình lớn */
@media (min-width: 992px) {
  .free-shipping {
    display: none;
  }
}


/* Tối ưu hóa cho điện thoại */
@media (max-width: 767px) {
  .navbar-brand {
    margin-left: 0;
    margin-right: 0;
    width: 60px;
  }

  .nav-link {
    padding: 15px;
    font-size: 14px;
  }

  .search-box {
    width: 50%;
    margin-bottom: 10px;
    margin-left: 0;
    
  }

  .button-introduce{
    padding-left: 0 !important;
  }

  .d-flex.align-items-center {
    flex-direction: column;
    align-items: flex-start;
  }


}

/* Tối ưu hóa cho máy tính */
@media (min-width: 768px) {
  .navbar-brand {
    margin-left: 0;
    margin-right: 2%;
  }

  .nav-link {
    padding: 20px;
    font-size: 18px;
  }
 

  .search-box {
    max-width: 100%;
  }

  .language-dropdown {
    margin-left: 20px;
  }

  .navbar-container {
    padding: 10px;
  }
}


.navbar-visible {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

.navbar-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.carousel-item {
  transition: opacity 0.5s ease-in-out;
}

.carousel-item-next, 
.carousel-item-prev, 
.carousel-item.active {
  display: block;
  opacity: 0;
}

.carousel-item-next-active, 
.carousel-item-prev-active {
  opacity: 1;
}
.image {
  width: 70px !important;  
  height: auto; 
}
