.wrap-main{
    background-color: #fff;
    padding-top: 100px;
}

  
  .content-main {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .title-main h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .content-detail {
    font-family: "Arial, Helvetica, sans-serif";
    font-size: 16px;
    line-height: 1.5;
  }
  
  .center-text {
    text-align: center;
  }
  
  .justify-text {
    text-align: justify;
  }
  
  .heading-text {
    font-size: 16px;
    font-weight: bold;
  }
  .img-contact{
    padding-top: 30px;
  }
