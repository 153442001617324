.wrap-main{
    padding-top: 100px;
}
.title-main{
   color:  #F59F05;
   padding-bottom: 20px;
   padding-top: 10px ;
}


.newshome-normal a{
    text-decoration: none;
}

/* Điều chỉnh cho màn hình máy tính bảng và điện thoại (<= 768px) */
@media screen and (max-width: 768px) {
    .wrap-main {
        padding-top: 50px; /* Giảm padding để phù hợp với màn hình nhỏ */
    }

    .title-main {
        padding-bottom: 15px;
        padding-top: 5px;
        font-size: 20px; /* Giảm kích thước chữ */
    }

    .name-newshome {
        font-size: 14px; /* Giảm kích thước chữ liên kết */
    }
}

/* Điều chỉnh cho màn hình rất nhỏ (<= 480px) */
@media screen and (max-width: 480px) {
    .wrap-main {
        padding-top: 50px; /* Giảm thêm padding cho màn hình rất nhỏ */
    }

    .title-main {
        padding-bottom: 10px;
        padding-top: 5px;
        font-size: 18px; /* Giảm kích thước chữ để vừa màn hình */
        text-align: center; /* Canh giữa tiêu đề trên màn hình nhỏ */
    }

    .name-newshome {
        font-size: 14px !important; /* Giảm kích thước chữ liên kết hơn nữa */
    }


}