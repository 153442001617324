.statistics-section {
    padding: 2rem 1rem;
    
  }
  .statistics-grid{
    background-color: #3c9c3d; /* Green background */
    color: white;
    text-align: center;
  }

  
  .statistics-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  
  .statistic-item {
    flex: 1 1 20%;
    max-width: 20%;
    min-width: 150px;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    background-color: #ffffff33; /* Slightly transparent white background */
    border-radius: 10px;
  }
  
  .statistic-item .icons {
    font-size: 2.5rem;
    text-align: center;
  }
  
  .statistic-value {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  
  .statistic-item p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #f8f8f8;
  }
  
  /* Responsive for tablets and medium screens */
@media (max-width: 992px) {
  .statistic-item {
    flex: 1 1 45%; /* Giảm kích thước cột để hiển thị 2 cột trên màn hình tablet */
    max-width: 45%;
  }
  
  .statistic-item .icon {
    font-size: 20px; /* Giảm kích thước biểu tượng cho màn hình nhỏ hơn */
  }

  .statistic-value {
    font-size: 20px;
  }

  .statistic-item p {
    font-size: 0.9rem;
  }
}

/* Responsive for small screens (phones) */
@media (max-width: 576px) {
  .statistic-item {
    flex: 1 1 100%; /* Chiếm 100% chiều rộng trên điện thoại */
    max-width: 100%;
    margin: 0.5rem 0; /* Giảm khoảng cách giữa các phần tử */
  }

  .statistic-item .icon img {
    width: 60px; /* Giảm kích thước icon trên màn hình nhỏ */
    height: 60px;
  }

  .statistic-item .icon {
    font-size: 20px;
  }

  .statistic-value {
    font-size: 20px; /* Điều chỉnh kích thước chữ cho đoạn giá trị */
  }

  .statistic-item p {
    font-size: 0.85rem;
  }
}


@media (max-width: 576px) {
  .statistics-section h2.title {
    font-size: 1.8rem; /* Điều chỉnh kích thước tiêu đề cho màn hình nhỏ */
  }
}
  