.desc-about p {
    font-size: 16px; /* Default paragraph font size */
    line-height: 2;
    color: #333;
}

.link-more {
    display: inline-block;
    padding: 10px 20px;
    color: #65b741;
    border: 2px solid #65b741;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.3s ease;
}

.link-more:hover {
    background: #65b741;
    color: #fff;
}

.about-flexbox {
    display: flex;
    gap: 20px;
}

.about-first, .about-last {
    width: 50%;
}

.about-first img, .about-last img {
    width: 100%;
    border-radius: 10px;
}

/* Media Query for small screens (phones) */
@media only screen and (max-width: 576px) {
    .name-company{
        font-size: 18px;
    }

    .desc-about p {
        font-size: 14px; /* Smaller paragraph size for mobile */
        line-height: 1.8;
    }

    .link-more {
        padding: 8px 16px; /* Smaller padding for links */
        font-size: 14px;
    }

    .about-flexbox {
        flex-direction: column; /* Stack images vertically */
        gap: 15px;
    }

    .about-first, .about-last {
        width: 100%;
    }
}

/* Media Query for medium screens (tablets) */
@media only screen and (min-width: 577px) and (max-width: 992px) {
    .name-company{
        font-size: 20px;
    }

    .desc-about p {
        font-size: 15px; /* Medium paragraph size */
        line-height: 1.9;
    }

    .link-more {
        padding: 9px 18px;
        font-size: 15px;
    }

    .about-flexbox {
        flex-direction: row; /* Arrange images side-by-side */
        gap: 18px;
    }

    .about-first, .about-last {
        width: 50%;
    }
}

/* Media Query for large screens (desktops) */
@media only screen and (min-width: 993px) {
    .name-company{
        font-size: 25px;
    }

    .desc-about p {
        font-size: 16px; /* Default paragraph size */
        line-height: 2;
    }

    .link-more {
        padding: 10px 20px;
        font-size: 16px;
    }

    .about-flexbox {
        gap: 20px;
    }

    .about-first, .about-last {
        width: 50%;
    }
}