.newsletter-button {
    margin-top: 20px;
  }
  /* Styling for the close button */
/* Styling for the close button */
.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #3c9c3d;
    font-size: 20px;
  }
  