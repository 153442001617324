.description{
    white-space: pre-wrap;
}
.main-detail-product{
    padding-top: 80px;
    padding-left: 5%;
    padding-right: 3%;
    padding-bottom: 3%;
    background-color: white;
}

.thumbnail-scroll {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 10px 0;
    gap: 10px;
}

.thumbnail-scroll::-webkit-scrollbar {
    height: 6px;
}

.thumbnail-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.thumbnail-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.right-detail-product{
    padding-left: 4%;
}

.safeInfomation{
    table {
        border-collapse: collapse;
        width: 100% !important;
        overflow-x: auto;
    }
    
    table, th, td {
        border: 1px solid black ;
        text-align: center ;
    }
  
}

/* Đổi màu chữ khi chưa click vào */
.custom-tabs .nav-link {
    color: #6c757d !important; /* Màu chữ mặc định cho tab chưa click */
  }
  
  .custom-tabs .nav-link.active {
    color: #000 !important; /* Màu chữ khi tab được chọn */
  }
  
  .custom-tabs .nav-link:hover {
    color: #007bff; /* Màu chữ khi hover vào tab */
  }

  a{
    text-decoration: none !important;
  }