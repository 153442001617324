
body {
  background-color: #4CAF50; /* Màu nền xanh lá cây */
  margin: 0; /* Loại bỏ margin mặc định */
  padding: 0; /* Loại bỏ padding mặc định */
  font-family: Arial, sans-serif; /* Đặt font mặc định cho ứng dụng */
}

.container {
  max-width: 100%; /* Đặt độ rộng tối đa cho container */
  margin: 0; /* Căn giữa container */
}
