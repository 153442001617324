.partners-carousel {
  background-color: #f8f8f8;
  text-align: center;
}



.partners-carousel a{
  text-decoration: none 
}

.single-line-ellipsis {
  white-space: nowrap;       /* Prevents the text from wrapping */
  overflow: hidden;          /* Hides the overflowing text */
  text-overflow: ellipsis;   /* Displays an ellipsis (...) when text overflows */
}





.slick-arrow {
  background-color: #3c9c3d !important; /* Màu nền của nút */
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1; /* Đảm bảo nút nằm trên các phần tử khác */
  position: absolute; /* Đảm bảo vị trí nút được căn chỉnh chính xác */
}

.slick-prev {
  left: 25px !important;
}

.slick-next {
  right: 25px !important;
}

.slick-slide {
  padding: 0 10px; /* Tạo khoảng cách giữa các item */
}

/* Media Queries */
@media (max-width: 1200px) {
  .slick-slide {
    padding: 0 8px;
  }
}

@media (max-width: 992px) {
  .slick-slide {
    padding: 0 6px;
  }

}

@media (max-width: 768px) {
  .slick-slide {
    padding: 0 5px;
  }


  .partners-carousel h2 {
    font-size: 1.8rem; /* Giảm kích thước tiêu đề */
  }
}

@media (max-width: 576px) {
  .slick-slide {
    padding: 0 4px;
  }


  .partners-carousel h2 {
    font-size: 1.5rem; /* Giảm kích thước tiêu đề */
  }

  .slick-arrow {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}
