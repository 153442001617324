.carousel-container {
    padding-top: 70px;
    padding-right: 0 !important;
    padding-left: 0 !important;

}
.container-fluid{
    padding-right: 0  !important;
    padding-left: 0 !important;
}
.carousel-item img {
    width: 100%;
    height: auto;
}



