.wrap-main {
    padding-top: 100px;
    padding-left: 70px;
    padding-right:70px;
}

.maxwidth {
    max-width: 1200px;
    margin: 0 auto;
}

.content-main {
    margin-top: 20px;
}

.w-clear::after {
    content: "";
    display: table;
    clear: both;
}

.title-main h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.time-main {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.content-detail p {
    margin-bottom: 20px;
    line-height: 1.6;
}

.content-detail img {
    margin-right: 10px;
    max-width: 100%;
    height: auto;
}

.share-line {
    margin: 20px 0;
    border-top: 1px solid #ddd;
}

.share {
    display: flex;
    justify-content: space-between;
}


.social-plugin .share-zalo,
.social-plugin .jssocials {
    margin-right: 10px;
}

.othernews {
    margin-top: 20px;
}

.list-news-other {
    list-style-type: none;
    padding: 0;
}

.list-news-other li {
    margin-bottom: 10px;
}

.list-news-other a {
    color: #007bff;
    text-decoration: none;
}

.list-news-other a:hover {
    text-decoration: underline;
}

.pagination-home {
    margin-top: 20px;
}
/* Điều chỉnh cho màn hình điện thoại */
@media screen and (max-width: 768px) {
    .wrap-main {
        padding-top: 50px;
       
    }

    .maxwidth {
        max-width: 100%;
    }

    .title-main h1 {
        font-size: 20px;
    }

    .time-main {
        font-size: 12px;
    }

    .share {
        flex-direction: column;
        align-items: flex-start;
    }


    .content-detail img {
        margin-right: 0;
        margin-bottom: 20px;
    }
}