.product-card {
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease;
    margin: 10px;
    border: 1px solid #ddd;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-card .card {
    border: none;
}

.sale-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 14px;
    /* animation: vibrate 1.2s infinite ease-in-out; */
}
