.row {
  display: flex;
  flex-wrap: wrap;
}

.col-md-6 {
  width: 50%;
  padding: 0 15px;
}

.why-item-all {
  display: flex;
  flex-direction: column;
}

.why-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.why-item_img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.why-item_text {
  margin-left: 20px;
}

.why-item_text h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #F59F05;
}

.why-item_text p {
  font-size: 1rem;
}

.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 1.5rem;
}

.text-center img {
  width: 100%; /* Điều chỉnh kích thước chiều rộng của hình ảnh */
  height: auto; /* Đảm bảo tỷ lệ chiều cao của hình ảnh được giữ nguyên */
  max-width: 120%; /* Đảm bảo hình ảnh không vượt quá kích thước phần tử chứa */
  border-radius: 8px; /* Nếu bạn muốn góc bo tròn */
}

/* Media Query for small screens (phones) */
@media only screen and (max-width: 576px) {
  .col-md-6 {
    width: 100%; /* Chỉnh width cho từng cột chiếm 100% trên điện thoại */
    padding: 10px;
  }

  .why-item {
    flex-direction: column; /* Sắp xếp các mục theo chiều dọc */
    text-align: center;
  }

  .why-item_img img {
    margin-bottom: 15px; /* Thêm khoảng cách giữa ảnh và text */
  }

  .why-item_text {
    margin-left: 0; /* Đặt lại margin để căn giữa trên điện thoại */
  }

  .why-item_text h3 {
    font-size: 1.2rem; /* Điều chỉnh kích thước chữ của tiêu đề */
  }

  .why-item_text p {
    font-size: 0.9rem; /* Điều chỉnh kích thước chữ của đoạn văn */
  }

  .text-center img {
    max-width: 100%; /* Đảm bảo hình ảnh không vượt quá kích thước trên điện thoại */
    border-radius: 5px;
  }
}

/* Media Query for medium screens (tablets) */
@media only screen and (min-width: 577px) and (max-width: 992px) {
  .col-md-6 {
    width: 100%; /* Chỉnh width cho từng cột chiếm 100% trên tablet */
  }

  .why-item_text h3 {
    font-size: 1.3rem; /* Điều chỉnh kích thước chữ trên tablet */
  }

  .why-item_text p {
    font-size: 0.95rem; /* Điều chỉnh kích thước đoạn văn trên tablet */
  }

  .text-center img {
    max-width: 100%; /* Hình ảnh tự co lại theo kích thước của tablet */
    border-radius: 6px;
  }
}

/* Media Query for large screens (desktops) */
@media only screen and (min-width: 993px) {
  .why-item_text h3 {
    font-size: 1.5rem; /* Kích thước mặc định trên desktop */
  }

  .why-item_text p {
    font-size: 1rem; /* Kích thước mặc định trên desktop */
  }

  .text-center img {
    max-width: 120%; /* Giữ nguyên kích thước tối đa của hình ảnh trên desktop */
  }
}
